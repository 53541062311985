import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

const Meetnow = () => {
  useEffect(() => {
    let navBar = document.getElementById("mainNav");
    navBar.classList.add("navbar-shrink");
  }, []);

  return (
    <Layout>
      <style
        dangerouslySetInnerHTML={{
          __html: `
                main > .container {
                    padding: 150px 15px 0;
                }
            `,
        }}
      />
      <Helmet
        title="Meet Now"
        bodyAttributes={{ class: "d-flex flex-column h-100" }}
      />
      <main class="flex-grow-1 bg-light">
        <div class="container">
          <section>
            <h2 class="text-center mt-0">Are you ready to enhance your indoor wayfinding?</h2>
            <hr class="divider" />
            <p className="text-center"><h4>You are in the right place.</h4> Eyedog.us brings a whole new dimension to the world of wayfinding. Enhance your patient experience. <a href="https://www.youtube.com/watch?v=ZrmS2Q0aGvM" target="_blank" rel="noopener noreferrer">Seeing is believing</a></p>
            <div className="row">
              <div className="col-md-12">
                <iframe
                  title="meet me"
                  width="100%"
                  height="800"
                  className="embed-responsive-item"
                  src="https://derekmoore-intraprise.zohobookings.com/portal-embed#/customer/4402176000000289032"
                  frameborder="0"
                  allowfullscreen
                >
                </iframe>
              </div>
            </div>
          </section>
        </div>
      </main>
    </Layout>
  );
};

export default Meetnow;
